import {
  AdBanner,
  AdLogo,
  AdvContainer,
  AdvWrapper,
  Layout,
  LogoWrapper,
} from '../../../style/Home/RecommendSection.module';
import Logo from '../../../resources/Ad/KCND LOGO 1.png';
import img01 from '../../../resources/Ad/240507_01.jpg';
import img07 from '../../../resources/Ad/240507_07_신규가입.jpg';
import React, { useEffect, useState, useRef } from 'react';
import Carousel from 'react-material-ui-carousel';
import { CREATE_LOG_MUTATION, GET_AD_BANNER } from '../gql/queries';
import { client } from '../../../client';
import { BannerClicked, LogoClicked, createObserver } from './Ad.module';

const LogType = {
  AD: 'AD',
  GENERAL_LOG: 'GENERAL_LOG',
  TEST: 'TEST',
};

export const MainAdv = () => {
  const boxRef = useRef([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const { data } = await client.query({
          query: GET_AD_BANNER,
          variables: {
            visible: true,
          },
        });

        const tempAds = data.getAdBannerList
          .filter((ad) => ad.account_id === 1) // accountId가 1인 요소로 필터링
          .map((ad, idx) => {
            return {
              ...ad,
              key: idx,
            };
          });

        setItems(tempAds);
      } catch (error) {
        console.error('Error fetching ads:', error);
      }
    };

    fetchAds();
  }, []);

  // View Log
  const [bannerIndex, setBannerIndex] = useState(items[0]?.key);

  useEffect(() => {
    const checkItems = () => {
      if (items.length > 0) {
        createObserver(bannerIndex, items, boxRef);
      } else {
        setTimeout(checkItems, 50);
      }
    };

    checkItems();
  }, [bannerIndex]);

  const handleCarouselChange = (index) => {
    setBannerIndex(index);
  };

  return (
    <Layout>
      <AdvContainer>
        <LogoWrapper>
          <AdLogo src={Logo} alt="" onClick={LogoClicked} />
        </LogoWrapper>

        <Carousel
          interval={5000}
          onChange={(index) => handleCarouselChange(index)}
        >
          {items.map((item, i) => (
            <AdvWrapper key={i} item={item}>
              <AdBanner
                key={item.key}
                src={item.image_url}
                alt={item.name}
                onClick={BannerClicked(item)}
                ref={(ref) => (boxRef.current[i] = ref)}
              />
            </AdvWrapper>
          ))}
        </Carousel>
      </AdvContainer>
    </Layout>
  );
};
