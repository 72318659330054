import styled from "styled-components";



export const LoginCenterContainer = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow:1;
    padding-top: 3rem;
`


export const LoginInputContainer = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 60%;
    margin-top: 2rem;
    margin-bottom: 3rem;
`

export const LoginButtinContainer = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    width: 60%;
`


export const SignUpContainer = styled.div`
    display: flex; 
    flex-direction: column;
    padding-top: 3rem;
    align-items: center;

`

export const SignUpInputListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  width: 80%;
`;

export const SignUpInputItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  margin-top: 1rem;
  align-items: center;
  width: 100%;
`;

export const SignUpInputItemText = styled.div`
  width: 100%;
`;