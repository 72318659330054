
import React, { useState, useEffect } from 'react';
import { SearchContainer, 
  StyledInput , 
  Layout, 
  Container, 
  SearchButton} from './search.module'
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import debounce from 'lodash/debounce'; // Import debounce
import { AutoCompleteBox, AutoCompleteText, AutoCompleteContainer } from './search.module';
import { ProductItem } from './ProductItem';



const createArray = (start, end) => {
  let array = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
};

export const ProductList = ({
  searchResult,
  keyword,
  minPrice,
  maxPrice,
  categoryId,
}) => {
  const checkPriceRange = (minPrice, maxPrice, productList) => {
    for (const product of productList) {
      const productMemberPriceFloat = parseFloat(
        product["price_member_eng"].replace(/\$/g, "")
      );
      if (
        productMemberPriceFloat >= minPrice &&
        productMemberPriceFloat <= maxPrice
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const checkCategoryId = (categoryId, productList) => {
    if (categoryId == null || categoryId == "undefined") return true;
    // 브랜드가 다르면 패스
    for (const product of productList) {
      if (product["brand"].replace(/\s/g, "") != keyword.replace(/\s/g, "")) {
        return false;
      }
    }
    // 카테고리 좁히기
    if (categoryId == 1) {
      for (const product of productList) {
        const df = product["duty_free_site"];
        const categoryId = product["category_id"];
        if (
          (df == "LOTTE" && createArray(2, 8).includes(categoryId)) ||
          (df == "SHILLA" && createArray(1, 23).includes(categoryId)) ||
          (df == "SSG" && createArray(1).includes(categoryId)) ||
          (df == "HYUNDAI" && createArray(1, 14).includes(categoryId))
        ) {
          return true;
        }
      }
      return false;
    } else if (categoryId == 2) {
      for (const product of productList) {
        const df = product["duty_free_site"];
        const categoryId = product["category_id"];
        if (
          (df == "LOTTE" && createArray(57, 61).includes(categoryId)) ||
          (df == "SHILLA" && createArray(24, 48).includes(categoryId)) ||
          (df == "SSG" && createArray(2, 2).includes(categoryId)) ||
          (df == "HYUNDAI" && createArray(15, 19).includes(categoryId))
        ) {
          return true;
        }
      }
      return false;
    } else if (categoryId == 4) {
      for (const product of productList) {
        const df = product["duty_free_site"];
        const categoryId = product["category_id"];
        if (
          (df == "LOTTE" && [66, 67, 68, 69].includes(categoryId)) ||
          (df == "SHILLA" &&
            [153, 154, 155, 156, 157, 158, 159].includes(categoryId)) ||
          (df == "SSG" && [21, 22, 23, 24, 25].includes(categoryId)) ||
          (df == "HYUNDAI" && [54, 55, 56].includes(categoryId))
        ) {
          console.log("보여줘야합니다.");
          return true;
        }
      }
      return false;
    } else if (categoryId == 3) {
      for (const product of productList) {
        const df = product["duty_free_site"];
        const categoryId = product["category_id"];
        if (
          (df == "LOTTE" && [62, 63, 64, 65].includes(categoryId)) ||
          (df == "SHILLA" && createArray(49, 66).includes(categoryId)) ||
          (df == "SSG" && createArray(3, 4).includes(categoryId)) ||
          (df == "HYUNDAI" && createArray(20, 23).includes(categoryId))
        ) {
          return true;
        }
      }
      return false;
    } else if (categoryId == 5) {
      for (const product of productList) {
        const df = product["duty_free_site"];
        const categoryId = product["category_id"];
        if (
          (df == "LOTTE" && [9, 15].includes(categoryId)) ||
          (df == "SHILLA" && createArray(67, 78).includes(categoryId)) ||
          (df == "SSG" && createArray(5, 5).includes(categoryId)) ||
          (df == "HYUNDAI" && createArray(24, 27).includes(categoryId))
        ) {
          return true;
        }
      }
      return false;
    } else if (categoryId == 6) {
      for (const product of productList) {
        const df = product["duty_free_site"];
        const categoryId = product["category_id"];
        if (
          (df == "LOTTE" && [16, 20].includes(categoryId)) ||
          (df == "SHILLA" && createArray(94, 104).includes(categoryId)) ||
          (df == "SHILLA" && createArray(149, 149).includes(categoryId)) ||
          (df == "SSG" && createArray(7, 8).includes(categoryId)) ||
          (df == "SSG" && createArray(31, 33).includes(categoryId)) ||
          (df == "HYUNDAI" && createArray(28, 30).includes(categoryId))
        ) {
          return true;
        }
      }
      return false;
    } else if (categoryId == 7) {
      for (const product of productList) {
        const df = product["duty_free_site"];
        const categoryId = product["category_id"];
        if (
          (df == "LOTTE" && [26, 29].includes(categoryId)) ||
          (df == "SHILLA" && createArray(79, 93).includes(categoryId)) ||
          (df == "SHILLA" && createArray(105, 114).includes(categoryId)) ||
          (df == "SHILLA" && createArray(150, 150).includes(categoryId)) ||
          (df == "SSG" && createArray(9, 9).includes(categoryId)) ||
          (df == "HYUNDAI" && createArray(31, 34).includes(categoryId))
        ) {
          return true;
        }
      }
      return false;
    } else if (categoryId == 8) {
      for (const product of productList) {
        const df = product["duty_free_site"];
        const categoryId = product["category_id"];
        if (
          (df == "LOTTE" && [34, 38].includes(categoryId)) ||
          (df == "SHILLA" && createArray(134, 137).includes(categoryId)) ||
          (df == "SHILLA" && createArray(152, 152).includes(categoryId)) ||
          (df == "SSG" && createArray(18, 20).includes(categoryId)) ||
          (df == "HYUNDAI" && createArray(47, 49).includes(categoryId))
        ) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  return (
    <Layout>
      {/* {minPrice} */}
      {searchResult.map((item, index) => {
        // 필터 없음
        if (minPrice == null || maxPrice == null)
          return (
            <ProductItem
              key={index}
              item={item}
              keyword={keyword}
            ></ProductItem>
          );

        // 필터 있음
        const isInPriceRange = checkPriceRange(
          minPrice,
          maxPrice,
          item["data"]
        );
        const isTargetCategory = checkCategoryId(categoryId, item["data"]);
        console.log("필터 있습니다. 카테고리 체크");
        if (isInPriceRange && isTargetCategory)
          return (
            <ProductItem
              key={index}
              item={item}
              keyword={keyword}
            ></ProductItem>
          );
        else return null;
      })}
    </Layout>
  );
};


