// AgreeModal.js
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography, Divider, Grid } from "@mui/material";

const AgreeModal = ({ open, handleClose, handleAgree }) => {
  const [agreements, setAgreements] = useState({
    agreeAll: false,
    agree1: false,
    agree2: false,
    agree3: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setAgreements((prevAgreements) => {
      const newAgreements = { ...prevAgreements, [name]: checked };

      // If "agreeAll" is checked, update all checkboxes
      if (name === "agreeAll") {
        newAgreements.agree1 = checked;
        newAgreements.agree2 = checked;
        newAgreements.agree3 = checked;
      } else {
        // If any individual checkbox is unchecked, "agreeAll" should be unchecked
        newAgreements.agreeAll =
          newAgreements.agree1 && newAgreements.agree2 && newAgreements.agree3;
      }

      return newAgreements;
    });
  };

  const [isAllAgreed, setIsAllAgreed] = useState(false);

  useEffect(() => {
    setIsAllAgreed(agreements.agree1 && agreements.agree2 && agreements.agree3);
  }, [agreements]);

  const [ulTextColor, setUlTextColor] = useState("green");
  // const ulTextColor = isAllAgreed ? "green" : "red"; // Example colors, adjust as needed
  useEffect(() => {
    setUlTextColor(isAllAgreed ? "green" : "red");
  }, [isAllAgreed]);

  const customIcon = (
    <img
      src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/all_inactive.png"
      alt="inactive"
      style={{ width: 24, height: 24 }}
    />
  );

  const customCheckedIcon = (
    <img
      src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/all_active.png"
      alt="active"
      style={{ width: 24, height: 24 }}
    />
  );
  const customIcon2 = (
    <img
      src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/agree_inactive.png"
      alt="inactive"
      style={{ width: 24, height: 24 }}
    />
  );

  const customCheckedIcon2 = (
    <img
      src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/agree_active.png"
      alt="active"
      style={{ width: 24, height: 24 }}
    />
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
    >
      <DialogTitle>
        <img
          src="https://dfx-front.s3.ap-northeast-2.amazonaws.com/logo1.png"
          alt="Logo"
          style={{ height: "40px" }}
        />
        <Divider style={{ marginTop: "4px", marginBottom: "0px" }} />
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              name="agreeAll"
              checked={agreements.agreeAll}
              onChange={handleCheckboxChange}
              icon={customIcon}
              checkedIcon={customCheckedIcon}
            />
          }
          label={<Typography variant="h10">전체 동의하기</Typography>}
          style={{
            fontWeight: "bold",
          }}
        />
        <DialogContentText
          style={{
            fontSize: "14px",
            marginLeft: "32px",
          }}
        >
          면세점가격비교 서비스 제공을 위해 회원정보가 제공됩니다. 보다 자세한
          개인정보 제공항목은 동의 내용에서 확인하실 수 있습니다. 해당 정보는
          동의 철회 또는 서비스 탈퇴 시 지체없이 파기됩니다.
        </DialogContentText>
        <Divider style={{ marginTop: "8px", marginBottom: "16px" }} />
        <Typography
          variant="subtitle1"
          style={{
            marginTop: "16px",
            marginBottom: "8px",
            marginLeft: "32px",
            fontWeight: "bold",
          }}
        >
          면세점가격비교 동의 항목
        </Typography>
        <ul
          style={{
            paddingLeft: "0px",
            marginTop: "0",
            listStyleType: "none",
            fontColor: ulTextColor,
          }}
        >
          <li>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agree1"
                      checked={agreements.agree1}
                      onChange={handleCheckboxChange}
                      icon={customIcon2}
                      checkedIcon={customCheckedIcon2}
                    />
                  }
                  label="[필수] 서비스 이용약관 동의"
                />
              </Grid>
              <Grid item xs={3} style={{ textAlign: "right" }}>
                <Button
                  variant="text"
                  style={{
                    fontSize: "12px",
                    color: "black",
                    textDecoration: "underline",
                    padding: 0,
                    minWidth: "auto",
                  }}
                  onClick={() => alert("추가 예정입니다.")}
                >
                  보기
                </Button>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agree2"
                      checked={agreements.agree2}
                      onChange={handleCheckboxChange}
                      icon={customIcon2}
                      checkedIcon={customCheckedIcon2}
                    />
                  }
                  label="[필수] 개인정보 처리방침"
                />
              </Grid>
              <Grid item xs={3} style={{ textAlign: "right" }}>
                <Button
                  variant="text"
                  style={{
                    fontSize: "12px",
                    color: "black",
                    textDecoration: "underline",
                    padding: 0,
                    minWidth: "auto",
                  }}
                  onClick={() => alert("추가 예정입니다.")}
                >
                  보기
                </Button>
              </Grid>
            </Grid>
          </li>
          <li>
            <FormControlLabel
              control={
                <Checkbox
                  name="agree3"
                  checked={agreements.agree3}
                  onChange={handleCheckboxChange}
                  icon={customIcon2}
                  checkedIcon={customCheckedIcon2}
                />
              }
              label="[필수] 면세점가격비교의 쿠폰 혜택과 마케팅 메시지를 카카오톡으로 받습니다."
            />
          </li>
        </ul>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", padding: 0 }}>
        <Button
          onClick={handleAgree}
          variant="contained"
          style={{
            backgroundColor: isAllAgreed ? "#888" : "#ccc",
            width: "100%",
            padding: "12px 0",
            fontSize: "16px",
            pointerEvents: isAllAgreed ? "auto" : "none",
          }}
          disabled={!isAllAgreed}
        >
          동의하고 계속하기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgreeModal;
