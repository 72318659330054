import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
  HomeContainer,
  HomeInnerContainer,
  ProductItemContainer,
  ProductItemInfo,
} from '../style/Home/Home.module';
import { TopMenu } from '../component/home/TopMenu';
import { HomeBanner } from '../component/home/HomeBanner';
import { BottomBanner } from '../component/common/BottomBanner';
import { SearchInput } from '../component/home/SearchInput';
import { RecommendSection } from '../component/home/RecommendSection';
import styled from 'styled-components';
import { MainCategorySection } from '../component/home/MainCategorySection';
import { TopBanner } from '../component/home/TopBanner';
import { MainAdv } from '../component/home/Adv/MainAdv';
import { gql } from '@apollo/client';
import BottomAd from "../component/ad-banner/BottomAd";
import { Footer } from "../component/common/Footer";
import { useNavigate } from "react-router-dom";

export const HomeV4 = () => {
  const navigate = useNavigate();

  const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `;
  const AiaFloatingButtonContainer = styled.div`
    position: fixed;
    bottom: 65px;
    right: 50%; /* 중앙 기준으로 우측 끝에 위치 */
    transform: translateX(190px); /* 우측으로 50px 이동 */

    color: #fff;
    padding: 10px 20px;
    border-radius: 80%;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s ease, transform 0.3s ease;
  `;
  const FloatingButtonContainer = styled.div`
    position: fixed;
    bottom: 5px;
    right: 50%; /* 중앙 기준으로 우측 끝에 위치 */
    transform: translateX(190px); /* 우측으로 50px 이동 */

    color: #fff;
    padding: 10px 20px;
    border-radius: 80%;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s ease, transform 0.3s ease;
  `;

  const AdContainer = styled.div`
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

    @media (max-width: 400px) {
      min-width: 360px; /* 400px 미만인 경우 */
      width: 100%; /* 400px 이상인 경우 */
    }
  `;
  // SessionID

  // AiaFloatingButtonContainer 클릭시 로그인 상태면 /aiappCoupon, 로그아웃 상태면 /login으로 이동
  const onClickAiaFloatingButton = () => {
    // 로그인 상태면 (accessToken 으로 체크한다.)
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      alert("쿠폰 페이지로 이동합니다.");
      navigate("/eventCoupon");
    } else {
      // alert("로그인 후 이용해주세요.");
      navigate("/event1");
    }
  };

  useEffect(() => {
    const generateSessionId = () => {
      const characters = "0123456789";

      return Array.from(
        { length: 5 },
        () => characters[Math.floor(Math.random() * characters.length)]
      ).join("");
    };

    const getSessionId = () => {
      let sessionId = sessionStorage.getItem("sessionId");

      if (!sessionId) {
        sessionId = generateSessionId();
        sessionStorage.setItem("sessionId", sessionId);
      }

      return sessionId;
    };

    getSessionId();
  }, []);

  return (
    <HomeContainer>
      <CenterContainer>
        <HomeInnerContainer>
          <TopMenu></TopMenu>
          <HomeBanner> </HomeBanner>
          <SearchInput></SearchInput>
          <MainAdv></MainAdv>
          <MainCategorySection></MainCategorySection>
          <RecommendSection></RecommendSection>
          {/* <DutyFreeCouponSection></DutyFreeCouponSection> */}
          {/* <AirportCouponSection></AirportCouponSection> */}
          {/* <PostSection></PostSection> */}
          <BottomBanner></BottomBanner>
        </HomeInnerContainer>
        <AdContainer>
          <BottomAd></BottomAd>
        </AdContainer>
        <Footer></Footer>
        <AiaFloatingButtonContainer onClick={() => onClickAiaFloatingButton()}>
          <img
            src={"https://dfx-img.s3.ap-northeast-2.amazonaws.com/aia2.png"}
            alt="로고 이미지"
            style={{ width: "50px", height: "50px", height: "auto" }}
          />
        </AiaFloatingButtonContainer>
        <FloatingButtonContainer>
          <a href="http://pf.kakao.com/_RjKPG">
            <img
              src={
                "https://dfx-img.s3.ap-northeast-2.amazonaws.com/kakaodfp.png"
              }
              alt="로고 이미지"
              style={{ width: "50px", height: "50px", height: "auto" }}
            />
          </a>
        </FloatingButtonContainer>
      </CenterContainer>
    </HomeContainer>
  );
};
