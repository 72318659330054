import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import { client } from "../../client";
import { TopBanner } from "../home/TopBanner";

export const Header = () => {
  // 환율
  const [exchangeRate, setExchageRate] = useState(null);
  const GET_EXCHANGE_RATE = gql`
    query getExchangeRate {
      getExchangeRate {
        rate
      }
    }
  `;
  const fetchData = (value) => {
    client
      .query({
        query: GET_EXCHANGE_RATE,
        variables: {
          search_keyword: value,
        },
        // fetchPolicy: 'no-cache',
      })
      .then((result) => {
        console.log(
          "result exchange rate...",
          result?.data?.getExchangeRate?.rate
        );
        setExchageRate(result?.data?.getExchangeRate?.rate);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // 기타

  const location = useLocation();
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/login");
  };

  const handleLogoutButton = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
    setIsLoggedin(false);
    // window.location.reload()
  };

  const [isLoggedIn, setIsLoggedin] = useState(false);
  useEffect(() => {
    // Check if the code cookie exists
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
    const codeCookie = cookies.find((cookie) => cookie.startsWith("code="));

    if (codeCookie) {
      // Code cookie exists, set login status
      const codeValue = codeCookie.split("=")[1];
      console.log("codeCookie...", codeCookie);
      console.log("code...", codeValue);

      // Perform actions based on login status
      if (codeValue != "") {
        // User is logged in
        // For example, update state, set authentication token, etc.
        setIsLoggedin(true);
      } else {
        // User is not logged in
        // For example, clear state, remove authentication token, etc.
        setIsLoggedin(false);
      }
    }
  }, []);

  // 다운로드 배너 보여주기 조건
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const threshold = 600;

      setShowBanner(
        window.location.pathname === "/" && viewportWidth >= threshold
      );
    };

    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        // position: "fixed", /* Fixed position */
        // top: "0", /* Stick to the top of the screen */
        zIndex: "1000",
      }}
    >
      <AppBar position="static">
        {showBanner ? (
          <TopBanner></TopBanner>
        ) : (
          !location.pathname.includes("aiapp") && (
            <Toolbar
              sx={{ justifyContent: "center", backgroundColor: "#000000" }}
            >
              <div
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  marginRight: "2rem",
                }}
              >
                오늘의 환율
              </div>
              <div style={{ color: "#baa1ff", textAlign: "center" }}>
                $1 = {exchangeRate} 원
              </div>
            </Toolbar>
          )
        )}
      </AppBar>
    </Box>
  );
};
